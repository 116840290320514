<template>
  <div class="login-container">
    <div class="auth-body">
      <div class="dashboard-card login-card">
        <div class="image-container hidden-mobile">
          <login-icon />
        </div>
        <div>
          <div v-if="isvalidVerifyCode">
            <h4>
              Reset Password 🔒
            </h4>
            <p class="mb-2">
              Your new password must be different from previously used passwords
            </p>

            <!-- form -->
            <validation-observer ref="resetPasswordForm">
              <b-form
                class="auth-reset-password-form mt-2"
                method="POST"
                @submit.prevent="validationForm"
              >

                <!-- password -->
                <b-form-group
                  label="New Password"
                  label-for="reset-password-new"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="resetPasswordForm.password"
                        :type="password1FieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="············"
                        autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  label-for="reset-password-confirm"
                  label="Confirm Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-confirm"
                        v-model="resetPasswordForm.passwordRepeat"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="reset-password-confirm"
                        placeholder="············"
                        autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <div class="d-flex align-items-center justify-content-between">
                  <b-link :to="{name:'login'}"  class="lab-text-info">
                    <feather-icon icon="ChevronLeftIcon" /> Back to login
                  </b-link>
                  <button class="lab-btn lab-btn-info" type="submit" :disabled="$store.getters.getLoader">
                    <spinner-loader />
                    Set New Password
                  </button>
                </div>
              </b-form>
            </validation-observer>
          </div>
          <div v-if="isvalidVerifyCode === false" >
            <h4>
              Reset Password 🔒
            </h4>
            <b-alert
              variant="danger"
              show
            >
              <div class="alert-body">
                <span>{{ errorMessage }}</span>
              </div>
            </b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store/index'

import LoginIcon from '@/layouts/icons/store/LoginIcon.vue'
// Alert
import AlertService from '@/common/alert.service'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LoginIcon,
  },
  data() {
    return {
      isvalidVerifyCode: null,
      errorMessage: '',
      resetPasswordForm: {
        password: '',
        passwordRepeat: '',
        verifyCode: '',
      },
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('account/changeResetPasswordEmail', this.resetPasswordForm)
            .then(response => {
              if (response.statusCode === 200) {
                AlertService.success(this, response.message, () => {
                  this.$router.push({ name: 'login' });
                });
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    onCheckVerifyCode() {
      const paramVerifyCode = this.$router.currentRoute.params.verifyCode;
      this.$store.dispatch('account/checkResetPasswordEmail', { verifyCode: paramVerifyCode })
        .then(response => {
          if (response.statusCode === 200) {
            this.resetPasswordForm.verifyCode = paramVerifyCode;
            this.isvalidVerifyCode = true;
          } else {
            AlertService.error(this, response.message);
            this.isvalidVerifyCode = false;
            this.errorMessage = response.message;
          }
        });
    },
  },
  created() {
    this.onCheckVerifyCode();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .auth-body {
    padding: 2.5rem;
    max-width: 1020px;
  }
 .login-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1.5rem;
    .image-container {
      margin-right: 1rem;
      padding: 2rem;
    }
    h4 {
      color: var(--lab-black);
    }

    .form-container {
      width: 500px;

      .forgot-password {
        font-size: 0.8rem;
        a {
          color: var(--lab-minted-elegance-color);
        }
      }
    }
  }

  .create-account {
    color: var(--lab-steel);
    font-size: 0.8rem;
    text-align: center;
    margin-top: 3rem;
    a {
      color: var(--lab-minted-elegance-color);
    }
  }

  .footer-container {
    .btn {
      padding: 0.6rem !important;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .btn-linkedin {
      background-color: #1608d8;
      border-color: #fff;
      border: none;
    }
    .btn-reddit {
      background-color: #FF4500;
      border-color: #fff;
      border: none;
    }
  }

  .verify-container {
    .info-desc, .alert-body {
      font-size: 0.85rem;
    }
    .remaining-time {
      span {
        color: var(--lab-steel);
        font-size: 0.9rem;
      }

      .send-again {
        color: var(--lab-minted-elegance-color);
        cursor: pointer;
      }
    }
  }

  .input-group {
    box-shadow: none !important;
  }
  .form-control:focus {
    border: 2px solid var(--lab-minted-elegance-color);
  }

  .login-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 769px) {
  .form-container {
    width: 300px !important;
  }
  .login-bottom {
    display: block !important;
  }
  .login-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 150px;
    }
  }
}
</style>
